import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CommentsSection from 'components/Comments/CommentsSection'
import SolutionsSection from 'components/Solutions/SolutionsSection'
import ImpactsSection from 'components/Impacts/ImpactsSection'
import { Tabs, TabPanel, Container } from '@mui/joy'
import ScrollableTabList, { TabLink }  from 'ui/ScrollableTabList'
import { Enemy } from 'util/constants'


interface Props {
    enemy: Enemy
}

const EnemyProfileTabs = ({ enemy }: Props) => {
    const { name, tab } = useParams()
    const [selectedTab, setSelectedTab] = useState(tab || 'comments')

    return (        
        <div className='grid grid-rows-auto grid-flow-row gap-2 divide-y divide-gray-300'>
            <Container>
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => {
                        setSelectedTab(`${newValue}`)
                    }}
                    aria-label="Enemy Profile Tabs"
                >
                    <ScrollableTabList
                        sticky="bottom"
                        disableUnderline={true}
                    >
                        <TabLink to={`/enemy/${name}/comments`} value="comments" disableIndicator={true}>
                            Comments
                        </TabLink>
                        <TabLink to={`/enemy/${name}/impacts`} value="impacts" disableIndicator={true}>
                            Impacts
                        </TabLink>
                        <TabLink to={`/enemy/${name}/solutions`} value="solutions" disableIndicator={true}>
                            Solutions
                        </TabLink>
                    </ScrollableTabList>        
                    <div className='grid grid-rows-auto grid-flow-row gap-2 divide-y divide-gray-300'>
                    <TabPanel value="comments">
                        <CommentsSection
                            commentable_id={enemy.id}
                            commentable_type="enemy"
                        />
                    </TabPanel>
                    </div>
                    <TabPanel value="impacts">
                        <ImpactsSection
                            enemy={enemy}
                        />
                    </TabPanel>
                    <TabPanel value="solutions">
                        <SolutionsSection
                            enemy={enemy}
                        />
                        </TabPanel>
                </Tabs>
            </Container>
        </div>
    )
}

export default EnemyProfileTabs