import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommentsSection from 'components/Comments/CommentsSection';
import ImpactEnemy from './ImpactEnemy';
import { Tabs, TabPanel, Container } from '@mui/joy';
import ScrollableTabList, { TabLink } from 'ui/ScrollableTabList'
import { Impact } from 'util/constants';

interface Props {
  impact: Impact;
}

const ImpactProfileTabs = ({ impact }: Props) => {
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const [selectedTab, setSelectedTab] = useState(tab || 'comments');

  return (
    <div>
      <Container>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(`${newValue}`);
          }}
          aria-label="Impact Profile Tabs"
        >
          <ScrollableTabList
            sticky="top"
            disableUnderline={true}
          >
            <TabLink
              to={`/impact/${id}/comments`}
              value="comments"
              disableIndicator={true}
            >
              Comments
            </TabLink>
            <TabLink
              component={Link}
              to={`/impact/${id}/enemy`}
              value="enemy"
              disableIndicator={true}
            >
              Enemy
            </TabLink>
          </ScrollableTabList>
          <TabPanel value="comments">
            <CommentsSection
              commentable_id={impact.id}
              commentable_type="impact"
            />
          </TabPanel>
          <TabPanel value="enemy">
            <ImpactEnemy enemyId={impact.enemy_id} />
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

export default ImpactProfileTabs;
