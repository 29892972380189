import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Tabs, TabPanel, Sheet } from '@mui/joy'
import { useParams } from 'react-router-dom'
import api from 'config/axiosConfig'
import ScrollableTabList, { TabLink } from 'ui/ScrollableTabList'
import Feed from '../Feed'
import UserProfileSaved from './UserProfileSaved'
import { User } from 'util/constants'
import { setDocumentTitle } from 'util/pageTitles'
import { capitalizeFirstLetter } from 'util/textUtils'

const UserProfile = () => {
    const { currentUser } = useUser()
    const { username, tab } = useParams<{ username: string; tab?: string }>()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'overview')
    const [usernameActive, setUsernameActive] = useState<User | null>(null)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        setDocumentTitle(`${username} - ${capitalizeFirstLetter(selectedTab)}`)
    }, [username, selectedTab])

    useEffect(() => {
        const fetchUsername = async () => {
            try {
                const response = await api.get<User>(`/api/user/${username}`)
                setUsernameActive(response.data)
            } catch (error) {
                console.error(`Error fetching user with username: ${username}`, error)
                setError(`User with username ${username} does not exist.`)
            }
        }

        fetchUsername()
    }, [username])

    const handleTabChange = (event: React.SyntheticEvent | null, newValue: string | number | null) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
        }
    }

    if (!usernameActive) {
        return <Sheet>{error}</Sheet>
    }

    return (
        <div className='py-0'>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="User Profile Tabs">
                    <div className='py-2 px-2'>
                    <ScrollableTabList sticky="top" disableUnderline={true}>
                        <TabLink to={`/user/${username}`} value="overview" disableIndicator>Overview</TabLink>
                        <TabLink to={`/user/${username}/entities`} value="entities" disableIndicator>Entities</TabLink>
                        <TabLink to={`/user/${username}/comments`} value="comments" disableIndicator>Comments</TabLink>
                        <TabLink to={`/user/${username}/feedback`} value="feedback" disableIndicator>Feedback</TabLink>
                        {username === currentUser?.username && (
                            <TabLink to={`/user/${username}/saved`} value="saved" disableIndicator>Saved</TabLink>
                        )}
                    </ScrollableTabList>
                    </div>
                    <TabPanel value="overview">
                        <Feed username={username} entityTypes={['enemy', 'impact', 'solution', 'comment', 'feedback']} hideTabList />
                    </TabPanel>
                    <TabPanel value="entities">
                        <Feed username={username} entityTypes={['enemy', 'impact', 'solution']} hideTabList />
                    </TabPanel>
                    <TabPanel value="comments">
                        <Feed username={username} entityTypes={['comment']} hideTabList />
                    </TabPanel>
                    <TabPanel value="feedback">
                        <Feed username={username} entityTypes={['feedback']} hideTabList />
                    </TabPanel>
                    {username === currentUser?.username && (
                        <TabPanel value="saved">
                            <UserProfileSaved />
                        </TabPanel>
                    )}
                </Tabs>
        </div>
    )
}

export default UserProfile