import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Tabs, Tab, TabPanel, Box, Sheet, Container } from '@mui/joy'
import ScrollableTabList from 'ui/ScrollableTabList'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import AdminReports from './AdminReports'
import AdminFeedback from './AdminFeedback'
import { setDocumentTitle } from 'util/pageTitles'
import { capitalizeFirstLetter } from 'util/textUtils'

const AdminPortal = () => {
    const { currentUser, awaitingUser } = useUser()
    const { tab } = useParams<{ tab: string }>()
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'reports')
    const [isAdmin, setIsAdmin] = useState<boolean | null | undefined>(null)

    useEffect(() => {
        setDocumentTitle(`Admin - ${capitalizeFirstLetter(selectedTab) ?? 'Home'}`);
    }, [selectedTab]);

    useEffect(() => {
        if (!awaitingUser) {
            if (currentUser !== null) {
                setIsAdmin(currentUser.attributes?.is_admin || false)
            }
        }
    }, [currentUser, awaitingUser])

    useEffect(() => {
        if (!awaitingUser && isAdmin) {
            setSelectedTab(tab || 'reports')
        }
    }, [awaitingUser, isAdmin, tab])

    const handleTabChange = (
        event: React.SyntheticEvent | null,
        newValue: string | number | null
    ) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
            navigate(newValue === 'reports' ? '/admin' : `/admin/${newValue}`)
        }
    }

    if (awaitingUser) {
        return <Sheet>Loading...</Sheet>
    }

    if (!awaitingUser && (!currentUser || !currentUser.attributes?.is_admin)) {
        return <Navigate to="/" />
    }

    return (
        <Container>
            <Box sx={{ width: '100%', marginTop: 4 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <ScrollableTabList disableUnderline={true} sticky="bottom">
                        <Tab value="reports" disableIndicator={true}>Reports</Tab>
                        <Tab value="feedback" disableIndicator={true}>Feedback</Tab>
                    </ScrollableTabList>
                    <TabPanel value="reports">
                        <AdminReports
                            isAdmin={isAdmin || false}
                        />
                    </TabPanel>
                    <TabPanel value="feedback">
                        <AdminFeedback
                            isAdmin={isAdmin || false}
                        />
                    </TabPanel>
                </Tabs>
            </Box>
        </Container>
    )
}

export default AdminPortal
