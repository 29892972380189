// SolutionEnemies.tsx

import React, { useEffect, useState } from 'react';
import api from 'config/axiosConfig';
import EnemyCard from 'components/Enemies/EnemyCard';
import AddEnemyToSolution from 'components/Enemies/AddEnemyToSolution';
import { CircularProgress } from '@mui/joy';
import { Enemy } from 'util/constants';

interface Props {
  solutionId: number;
}

interface RelatedEnemy extends Enemy {
  enemy_id: number;
}

const SolutionEnemies = ({ solutionId }: Props) => {
  const [relatedEnemies, setRelatedEnemies] = useState<RelatedEnemy[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRelatedEnemies = async () => {
      try {
        const response = await api.get<RelatedEnemy[]>(
          `/api/solutions/${solutionId}/enemies`
        );
        const enemyIds = response.data.map((enemy) => enemy.enemy_id);
        const enemiesResponse = await api.get<RelatedEnemy[]>(
          `/api/enemies?enemyIds=${JSON.stringify(enemyIds)}`
        );
        setRelatedEnemies(enemiesResponse.data);
      } catch (err) {
        setError('Failed to load related enemies. Please try again later.');
      }
    };

    fetchRelatedEnemies();
  }, [solutionId]);

  const handleEnemyAdded = (newEnemy: RelatedEnemy) => {
    setRelatedEnemies((prevEnemies) => [...prevEnemies, newEnemy]);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!relatedEnemies.length) {
    return (
      <div>
        <CircularProgress size="lg" />
      </div>
    );
  }

  return (
    <div className="related-enemies-container">
      <AddEnemyToSolution solutionId={solutionId.toString()} onEnemyAdded={handleEnemyAdded} />
      <div className="entity-divider">
      {relatedEnemies.map((enemy) => (
        <EnemyCard
          key={enemy.id}
          enemy={enemy}
          showMeta={true}
          showActions={true}
          onVote={() => {}}
          truncateDescription={true}
        />
      ))}
      </div>
    </div>
  );
};

export default SolutionEnemies;
