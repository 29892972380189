import { useEffect, useRef, useState } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { Link } from '@tiptap/extension-link';
import { Image } from '@tiptap/extension-image';
import { Youtube } from '@tiptap/extension-youtube';
import CustomMention from './CustomMention';
import userSuggestion from './userSuggestion';
import CustomHashtag from './CustomHashtag';
import hashtagSuggestion from './hashtagSuggestion';
import { EditorContent, Editor as TipTapEditor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import 'tippy.js/dist/tippy.css';
import MenuBar from './MenuBar';

interface TextEditorProps {
  value: string;
  onChange: (content: string) => void;
  editorType: 'comment' | 'entity';
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  editorType,
}) => {
  const [editorLoaded, setEditorLoaded] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: {
          HTMLAttributes: {
            class: 'max-w-[100%]',
          },
        },
      }),
      Placeholder.configure({
        placeholder: 'Write content...',
      }),
      Underline,
      Link.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: true,
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'max-w-[100%]',
        },
      }),
      Youtube.configure({
        width: 256,
        height: 144,
      }),
      CustomMention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: userSuggestion,
      }),
      CustomHashtag.configure({
        HTMLAttributes: {
          class: 'hashtag',
        },
        suggestion: hashtagSuggestion,
      }),
    ],
    autofocus: 'end',
    editorProps: {
      attributes: {
        class:
          'flex flex-col px-4 py-3 justify-start border-b border-r border-l border-gray-700 items-start w-full gap-3 pt-4 rounded-bl-md rounded-br-md outline-none',
      },
    },
    content: value || '',
    onCreate: () => setEditorLoaded(true),
    onUpdate({ editor }) {
      const content = editor.getHTML();
      onChange(content);
    },
  });

  const editorRef = useRef<TipTapEditor | null>(null);

  useEffect(() => {
    if (editor) {
      editorRef.current = editor;
    }
  }, [editor]);

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value || '');
    }
  }, [value, editor]);

  return !editorLoaded ? (
    <div className="h-24 animate-pulse rounded bg-gray-200"></div>
  ) : (
    <div>
      <MenuBar editor={editor} editorType={editorType} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default TextEditor;