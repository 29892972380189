import React from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import EntitiesList from './Entities/EntitiesList'
import ScrollableTabList, { TabLink } from 'ui/ScrollableTabList'
import { Tabs, TabPanel, Box } from '@mui/joy'
import { Sort, EntityType } from 'util/constants'
import EntitiesSort from './Entities/EntitiesSort'
import EnemiesListSelector from './Entities/EnemiesListSelector'
import ImpactsListSelector from './Entities/ImpactsListSelector'
import SolutionsListSelector from './Entities/SolutionsListSelector'

interface FeedProps {
  username?: string
  entityTypes?: EntityType[]
  hideTabList?: boolean
}

const Feed: React.FC<FeedProps> = ({ username, entityTypes, hideTabList = false }) => {
    const { entityType: paramEntityType, sort: paramSort = 'new' } = useParams<{ entityType?: EntityType, sort?: Sort }>()
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const sort = (searchParams.get('sort') as Sort) || paramSort

    const currentEntityType = paramEntityType || (entityTypes && entityTypes[0]) || 'enemies'

    const getEntityTypeForApi = (type: string): EntityType => {
        switch (type) {
            case 'enemies': return 'enemy'
            case 'impacts': return 'impact'
            case 'solutions': return 'solution'
            case 'comments': return 'comment'
            case 'feedback': return 'feedback'
            default: return type as EntityType
        }
    }

    const handleSortChange = (newSort: Sort) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('sort', newSort)
        if (username) {
            navigate(`${location.pathname}?${newSearchParams.toString()}`)
        } else {
            navigate(`/${currentEntityType}/${newSort}?${newSearchParams.toString()}`)
        }
    }

    const renderEntitiesList = (type: string) => (
        <>
            {username ? (
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', py: .5, px: 2 }}>
                    <EntitiesSort entityType={type} sort={sort} onSortChange={handleSortChange} />
                </Box>
            ) : (
                <>
                    {type === 'enemies' && <EnemiesListSelector sort={sort} searchParams={searchParams} />}
                    {type === 'impacts' && <ImpactsListSelector sort={sort} searchParams={searchParams} />}
                    {type === 'solutions' && <SolutionsListSelector sort={sort} searchParams={searchParams} />}
                </>
            )}
            <EntitiesList 
                entityTypes={[getEntityTypeForApi(type)]} 
                sort={sort as Sort} 
                searchParams={searchParams}
                username={username}
            />
        </>
    )

    if (hideTabList) {
        return renderEntitiesList(currentEntityType)
    }

    return (
        <div className='py-0'>
            <Tabs value={currentEntityType}>
                {!hideTabList && (
                    <div className='py-2 px-2'>
                    <ScrollableTabList disableUnderline>
                        <TabLink to="/enemies/top" value="enemies" disableIndicator>Enemies</TabLink>
                        <TabLink to="/impacts/top" value="impacts" disableIndicator>Impacts</TabLink>
                        <TabLink to="/solutions/top" value="solutions" disableIndicator>Solutions</TabLink>
                    </ScrollableTabList>
                    </div>
                )}
                {entityTypes ? (
                    entityTypes.map(type => (
                        <TabPanel key={type} value={type}>
                            {renderEntitiesList(type)}
                        </TabPanel>
                    ))
                ) : (
                    <>
                        <TabPanel value="enemies">{renderEntitiesList('enemies')}</TabPanel>
                        <TabPanel value="impacts">{renderEntitiesList('impacts')}</TabPanel>
                        <TabPanel value="solutions">{renderEntitiesList('solutions')}</TabPanel>
                    </>
                )}
            </Tabs>
        </div>
    )
}

export default Feed