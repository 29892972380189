import React, { useState, useEffect, useRef, useCallback } from 'react'
import { transformHtmlContent } from 'util/transformHtmlContent';
import { useUser } from 'util/UserContext'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import api from 'config/axiosConfig'
import { formatRelativeTime } from 'config/dayjsConfig';
import RenderComments from './RenderComments'
import CommentInput from './CommentInput'
import './Comment.css'
import EntityActions from '../Entities/EntityActions'
import Report from 'components/Entities/Report'
import { Comment as CommentData } from 'util/constants'
import { Menu, MenuItem, Box, Avatar } from '@mui/joy'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'

interface CommentsProps extends CommentData {
    depth: number
    maxDepth: number
    isAllCollapsed: boolean
    onAddReply: (parent_id: string, content: string) => void
    onUpdateComment: (id: string, content: string) => void
    onDeleteComment: (id: string) => void
}

const Comment: React.FC<CommentsProps> = ({
    id,
    username,
    properties_public,
    user_id,
    created_ts,
    content,
    replies,
    commentable_type,
    commentable_id,
    upvotes,
    downvotes,
    vote_count,
    parent_id,
    root_id,
    updated_ts,
    is_active,
    commentable_entity,
    depth = 0,
    maxDepth = 3,
    isAllCollapsed = false,
    onAddReply,
    onUpdateComment,
    onDeleteComment,
}) => {
    const { currentUser, setShowAuthModal } = useUser()
    const [isAuthor, setIsAuthor] = useState<boolean>(false)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isReplying, setIsReplying] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const moreIconRef = useRef<HTMLButtonElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const reportModalRef = useRef<HTMLDivElement>(null)
    const hasReplies = replies && replies.length > 0
    const location = useLocation()
    const navigate = useNavigate()
    const entity = {
        id,
        name: null,
        eid: null,
        username,
        user_id,
        upvotes,
        downvotes,
        vote_count,
        content,
        commentable_type,
        commentable_id,
        created_ts,
        is_active,
        replies,
        parent_id,
        root_id,
        updated_ts,
        properties: null,
        properties_public: properties_public,
        attributes: null,
        commentable_entity: undefined,
    }

    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    const [isLoadingEnemyName, setIsLoadingEnemyName] = useState(false);

    const getEnemyNameById = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/api/enemies/id/${id}`);
            return response.data.name.replace(/ /g, '_');
        } catch (error) {
            console.error('Error fetching enemy name:', error);
            return null;
        }
    }, []);

    useEffect(() => {
        setIsAuthor(currentUser?.username === username)
    }, [currentUser, username])

    useEffect(() => {
        setIsCollapsed(depth >= maxDepth || isAllCollapsed)
    }, [depth, maxDepth, isAllCollapsed])

    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleReplyClick = async () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else {
            if (location.pathname.includes(`${commentable_type}`)) {
                setIsReplying(!isReplying);
            } else {
                setIsLoadingEnemyName(true);
                let path;
                if (commentable_type === 'enemy') {
                    const enemyName = await getEnemyNameById(commentable_id);
                    path = `/enemy/${enemyName}/comments/${id}`;
                } else {
                    path = `/${commentable_type}/${commentable_id}/comments/${id}`;
                }
                setIsLoadingEnemyName(false);
                navigate(path);
            }
        }
    };

    const handleEditClick = async () => {
        if (!currentUser) {
            handleMenuClose()
            setShowAuthModal(true)
        } else {
            handleMenuClose()
            if (location.pathname.includes(`${commentable_type}`)) {
                setIsEditing(!isEditing);
            } else {
                setIsLoadingEnemyName(true);
                let path;
                if (commentable_type === 'enemy') {
                    const enemyName = await getEnemyNameById(commentable_id);
                    path = `/enemy/${enemyName}/comments/${id}`;
                } else {
                    path = `/${commentable_type}/${commentable_id}/comments/${id}`;
                }
                setIsLoadingEnemyName(false);
                navigate(path);
            }
        }
    };

    const handleDeleteClick = async () => {
        if (!currentUser) {
            handleMenuClose()
            setShowAuthModal(true)
        } else {
            if (location.pathname.includes(`${commentable_type}`)) {
                handleDeleteComment();
            } else {
                setIsLoadingEnemyName(true);
                let path;
                if (commentable_type === 'enemy') {
                    const enemyName = await getEnemyNameById(commentable_id);
                    path = `/enemy/${enemyName}/comments/${id}`;
                } else {
                    path = `/${commentable_type}/${commentable_id}/comments/${id}`;
                }
                setIsLoadingEnemyName(false);
                navigate(path);
            }
        }
    };

    const handleReportClick = () => {
        handleMenuClose() // Close the menu
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setIsReportModalOpen(false)
    }

    const handleAddReply = (replyContent: string) => {
        if (id) {
            onAddReply(id, replyContent)
            setIsCollapsed(false)
            setIsReplying(false)
        }
    }

    const handleUpdateComment = (updatedContent: string) => {
        if (id) {
            onUpdateComment(id, updatedContent)
            setIsCollapsed(false)
            setIsEditing(false)
        }
    }

    const handleDeleteComment = () => {
        handleMenuClose()
        if (id) {
            if (
                window.confirm('Are you sure you want to delete this comment?')
            ) {
                onDeleteComment(id)
            }
        }
    }

    return (
        <div className={`my-2 ${depth > 0 ? 'pl-[3px]' : ''}`}>
            <div className="flex space-x-0">
                <div className="comment-tree-line flex flex-col items-center">
                    <div className="relative flex items-center justify-center">
                        <div
                            className={`${depth > 0 ? 'absolute bottom-1/2 right-full h-[16px] w-[15.51px] rounded-bl-[10px] border-0 border-b-[0.5px] border-l-[0.5px] border-solid border-gray-300' : ''}`}
                        ></div>
                        <Avatar
                            className="rounded-full"
                            alt={username !== '[deleted]' ? username || "Profile" : `D`}
                            component={username !== '[deleted]' ? Link : 'span'}
                            to={username !== '[deleted]' ? `/user/${username}` : ''}
                            src={properties_public?.avatar_key ? `${IMAGE_CDN}/${properties_public.avatar_key}` : undefined}
                            sx={{ width: 24, height: 24 }}
                        />
                    </div>
                    {hasReplies && (
                        <div className="group relative mb-4 mt-2 w-5 flex-grow">
                            <div
                                className={`absolute bottom-2 left-1/2 h-full w-[1px] -translate-x-1/2 transform bg-gray-300 group-hover:bg-gray-700`}
                            ></div>
                            <button
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                className="absolute inset-0 flex items-end justify-center"
                            >
                                <div
                                    className={`absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 transform pb-3 pt-2`}
                                >
                                    {isCollapsed ? (
                                        <AddCircleOutlineOutlinedIcon className="rounded-full text-gray-500 group-hover:text-gray-700" />
                                    ) : (
                                        <RemoveCircleOutlineOutlinedIcon className="rounded-full text-gray-500 group-hover:text-gray-700" />
                                    )}
                                </div>
                            </button>
                        </div>
                    )}
                    <div></div>
                </div>

                <div className="flex-grow ml-0">
                    <article>
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="flex items-center gap-2 text-sm">
                                    {username !== '[deleted]' ? (
                                        <Link
                                            to={`/user/${username}`}
                                            className="ml-2 font-semibold text-gray-950 dark:text-gray-300"
                                        >
                                            {username}
                                        </Link>
                                    ) : (
                                        <span className="ml-2 font-semibold text-gray-950 dark:text-gray-300">
                                            {username}
                                        </span>
                                    )}
                                    <span className="text-gray-400">
                                        {`•`} {formatRelativeTime(created_ts)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isEditing ? (
                             <Box
                                className="comment-content mx-2 my-2 text-sm"
                            >
                            <CommentInput
                                onSubmit={handleUpdateComment}
                                onCancel={() => setIsEditing(false)}
                                buttonText="Update"
                                initialContent={content}
                            />
                            </Box>
                        ) : (
                            <>
                                <Box className="comment-content mx-2 my-2 text-sm">
                                    {transformHtmlContent(content)}
                                </Box>
                                <div className="flex flex-row items-center gap-2">
                                    <EntityActions
                                        entity={entity}
                                        entityType="comment"
                                    />
                                    <button
                                        className="text-md mt-2 flex cursor-pointer items-center justify-center gap-2 rounded-full px-2 py-2 font-semibold transition-colors hover:bg-gray-200 md:mt-0"
                                        onClick={handleReplyClick}
                                        disabled={isLoadingEnemyName}
                                    >
                                        <ChatOutlinedIcon />
                                        <span className="text-xs">{isLoadingEnemyName ? 'Loading...' : 'Reply'}</span>
                                    </button>
                                    <div className="icon-more mt-0 text-xl md:mt-0">
                                        <button
                                            className="size-9 items-center justify-center rounded-full pb-0.5 transition-colors hover:bg-gray-300 md:pb-1"
                                            onClick={handleMenuToggle}
                                            ref={moreIconRef}
                                            disabled={!is_active}
                                        >
                                            <MoreHorizOutlinedIcon className="text-eoe-dark-gray"/>
                                        </button>
                                    </div>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleMenuClose}
                                        ref={menuRef}
                                    >
                                        {isAuthor &&
                                            currentUser?.id === user_id && (
                                                <>
                                                    <MenuItem
                                                        onClick={handleEditClick}
                                                    >
                                                        <EditOutlinedIcon />
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={handleDeleteClick}
                                                    >
                                                        <DeleteOutlineOutlinedIcon />
                                                    </MenuItem>
                                                </>
                                            )}
                                        <Report
                                            entityId={id}
                                            entityType="comment"
                                            isOpen={isReportModalOpen}
                                            setIsOpen={setIsReportModalOpen}
                                            modalRef={reportModalRef}
                                            onReportClick={handleReportClick}
                                        />
                                    </Menu>
                                </div>
                            </>
                        )}
                    </article>
                    {isReplying && (
                        <Box
                            className="comment-content mx-2 my-2 text-sm"
                        >
                            <CommentInput
                                onSubmit={handleAddReply}
                                onCancel={() => setIsReplying(false)}
                                buttonText="Reply"
                            />
                        </Box>
                    )}
                    {hasReplies && !isCollapsed && (
                        <div className="comment-content text-sm">
                            <RenderComments
                                comments={replies}
                                depth={depth + 1}
                                maxDepth={maxDepth}
                                isAllCollapsed={isAllCollapsed}
                                onAddReply={onAddReply}
                                onUpdateComment={onUpdateComment}
                                onDeleteComment={onDeleteComment}
                                commentable_type={commentable_type}
                                commentable_id={commentable_id}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Comment
