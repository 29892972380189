// SolutionProfileTabs.tsx

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommentsSection from 'components/Comments/CommentsSection';
import SolutionEnemies from 'components/Solutions/SolutionProfile/SolutionEnemies';
import { Tabs, TabPanel, Container } from '@mui/joy';
import ScrollableTabList, { TabLink } from 'ui/ScrollableTabList'
import { Solution } from 'util/constants';

interface Props {
  solution: Solution;
}

const SolutionProfileTabs = ({ solution }: Props) => {
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const [selectedTab, setSelectedTab] = useState(tab || 'comments');

  return (
    <div>
      <Container>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(`${newValue}`);
          }}
          aria-label="Solution Profile Tabs"
        >
          <ScrollableTabList
            sticky="top"
            disableUnderline={true}
          >
            <TabLink
              to={`/solution/${id}/comments`}
              value="comments"
              disableIndicator={true}
            >
              Comments
            </TabLink>
            <TabLink
              component={Link}
              to={`/solution/${id}/enemies`}
              value="enemies"
              disableIndicator={true}
            >
              Related Enemies
            </TabLink>
          </ScrollableTabList>
          <TabPanel value="comments">
            <CommentsSection
              commentable_id={solution.id}
              commentable_type="solution"
            />
          </TabPanel>
          <TabPanel value="enemies">
            <SolutionEnemies solutionId={solution.id} />
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

export default SolutionProfileTabs;
